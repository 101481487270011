<template>
  <div class="user-modal" ref="userRef">
    <artmisads-modal
      v-model:open="props.showUserModal"
      title="Add User"
      :width="600"
      :getContainer="() => userRef"
      :destroyOnClose="true"
      :maskClosable="false"
      :onCancel="onCancel"
    >
      <div class="user-modal-wrap">
        <a-spin :spinning="spinning">
          <a-form>
            <a-form-item 
              v-bind="validateInfos.name"
              label="Username"
            >
              <artmisads-input v-model:value="modelRef.name"/>
            </a-form-item>
            <a-form-item  
              label="Email"
              v-bind="validateInfos.email"
            >
              <artmisads-input v-model:value="modelRef.email"/>
            </a-form-item>
            <a-form-item
              v-bind="validateInfos.password"
              label="Passoword"
            >
              <artmisads-input v-model:value="modelRef.password"/>
            </a-form-item>
          </a-form>
        </a-spin>
      </div>
      <template #footer>
        <artmisads-button @click="onCancel">Cancel</artmisads-button>
        <artmisads-button @click="onConfirm" type="primary">Confirm</artmisads-button>
      </template>
    </artmisads-modal>
  </div>
</template>

<script setup>
import { message, Form } from "ant-design-vue";
import { ref, reactive, watch, onMounted } from "vue";
import { publisherAdminApi } from "~/server/index";

const spinning = ref(false);
const userRef = ref(null);
const props = defineProps([
  "showUserModal",
  "setShowUserModal",
  'editUserInfo', 'getList'
]);

const useForm = Form.useForm;

const modelRef = ref({
  name: '',
  email: '',
  password: '',
});

const rulesRef = reactive({
  name: [{
    required: true,
    message: 'Full Name cannot be empty',
  }],
  email: [{
    required: true,
    type: 'email',
    message: 'Invalid email',
  }],
  password: [{
    required: true,
    message: 'Password cannot be empty',
  }],
});

const { validateInfos, validate, resetFields } = useForm(modelRef, rulesRef);

const onCancel = () => {
  resetFields();
  props.setShowUserModal(false);
};

watch(() => props.editUserInfo, info => {
  if (info.id) {
    modelRef.value = {...info, password: ''};
  }
})

const onConfirm = async () => {
  const validateRes = await validate();
  if (!validateRes.errorFields) {
    spinning.value = true;
    let res;
    const params = validateRes;
    if (props.editUserInfo.id) {
      params.id = props.editUserInfo.id;
      res = await publisherAdminApi.updateUser(params);
    } else {
      res = await publisherAdminApi.addUser(params);
    }
    const { error, success } = res;
    if (success) {
      message.success('Success');
      onCancel();
      props.getList()
    } else {
      console.log(error);
    }
    spinning.value = false;
  }
};

</script>

<style lang="less" scoped>
  .user-modal {
    :deep(.ant-form) {
      .ant-form-item-label {
        width: 120px;
      }
    }
  }
 
</style>
