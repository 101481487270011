<template>
  <div class="users">
    <a-spin :spinning="spinning">
      <breadcrumbs :breadcrumbsData="breadcrumbsData">
      </breadcrumbs>
      <div style="display: flex; justify-content: right; margin-bottom: 10px">
        <artmisads-button @click="onAddUserModal" type="primary" size="middle">Add User</artmisads-button>
      </div>
      <div class="table" ref="tableRef">
        <div class="search-btn">
          <a-input-group compact style="width: 335px">
            <artmisads-select v-model:value="searchType" style="width: 120px">
              <a-select-option value="name">Name</a-select-option>
              <a-select-option value="email">Email</a-select-option>
            </artmisads-select>
            <a-input v-model:value="inputValue" style="width: 200px" />
          </a-input-group>
          <artmisads-select v-model:value="params.status" style="width: 150px">
            <a-select-option :value="0">正常</a-select-option>
            <!-- <a-select-option :value="1">锁定</a-select-option> -->
            <!-- <a-select-option :value="2">注销</a-select-option> -->
            <a-select-option :value="3">禁用</a-select-option>
          </artmisads-select>
          <div class="reset-btn">
            <artmisads-button
              @click="getList"
              type="primary"
              size="middle"
              :isSquare="true"
              >Search</artmisads-button
            >
            <artmisads-select-button
              @click="onReset"
              size="middle"
              :isSquare="true"
              >Reset</artmisads-select-button
            >
          </div>
        </div>
        <artmisads-table
          :columns="columns"
          :data-source="dataList"
          @change="onChangePage"
          :pagination="{
            total: listTotal,
            current: params.page,
            pageSize: params.size,
            showSizeChanger: false,
          }"
        >
          <template #headerCell="{ column }">
            <template v-if="column.key === 'name'">
              <span>
                <smile-outlined />
                Name
              </span>
            </template>
            <template v-else>
              <span>
                {{ column.name }}
              </span>
            </template>
          </template>
          <template #bodyCell="{ column, text, record }">
            <template v-if="column.dataIndex === 'status'">
              <div class="editable-cell">
                <a-tag v-if="record.status === 0">正常</a-tag>
                <!-- <a-tag v-if="record.status === 2">锁定</a-tag> -->
                <!-- <a-tag v-if="record.status === 3">注销</a-tag> -->
                <a-tag v-if="record.status === 1">禁用</a-tag>
              </div>
            </template>
            <template v-else-if="column.dataIndex === 'isActive'">
              <div class="editable-cell-btn">
                <a-popconfirm
                  title="Are you sure disable this user?"
                  ok-text="Confirm"
                  cancel-text="Cancel"
                  @confirm="() => onChange(false, record.id)"
                  :getPopupContainer="() => tableRef"
                  v-if="record.isActive"
                >
                  <artmisads-select-button type="" size="small">Disable</artmisads-select-button>
                </a-popconfirm>
                <artmisads-select-button @click="() => onChange(true, record.id)" v-else size="small">Active</artmisads-select-button>
                <artmisads-select-button @click="() => onEdit(record)" size="small">Edit</artmisads-select-button>
              </div>
            </template>
          </template>
        </artmisads-table>
      </div>
      <userModal
        :editUserInfo="editUserInfo"
        :showUserModal="showUserModal"
        :setShowUserModal="setShowUserModal"
        :getList="getList"
      />
    </a-spin>
  </div>
</template>

<script setup>
import { useUserInfoStore } from '../../../storeData/index';
import { ref, reactive, toRaw, onMounted } from "vue";
import { publisherAdminApi } from "~/server/index";
import breadcrumbs from "../breadcrumbs.vue";
import userModal from "./userModal.vue";
import { message } from "ant-design-vue";

const store = useUserInfoStore();
const tableRef = ref(null);
const dataList = ref([]);
const editUserInfo = ref({
  email: '',
  name: '',
  id: ''
})
const spinning = ref(false);
const showUserModal = ref(false);
const listTotal = ref(0);
const inputValue = ref("");
const searchType = ref("name");

const params = ref({
  emailKeyword: "",
  nameKeyword: "",
  page: 1,
  size: 10,
  status: 0, // 0, 1, 2, 3
});

const breadcrumbsData = {
  title: "Users",
  des: "admin account list",
  links: [
    {
      name: "Home",
      path: "/#/operation/users",
    },
    {
      name: "Users",
      path: "/#/operation/users",
    },
  ],
};

const columns = [
  {
    title: "Name",
    name: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    name: "Email",
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Status",
    name: "Status",
    dataIndex: "status",
    key: "status",
  },
  {
    title: "Operation",
    name: "Active",
    key: "isActive",
    dataIndex: "isActive",
  },
];

const onEdit = info => {
  if (info) {
    const { id, email, name } = info;
    editUserInfo.value = { id, email, name };
    showUserModal.value = true;
  }
}

const onAddUserModal = () => {
  setShowUserModal(true);
}

const setShowUserModal = (status) => {
  showUserModal.value = status;
};

const onReset = () => {
  searchType.value = "name";
  inputValue.value = "";
  params.value = {
    emailKeyword: "",
    nameKeyword: "",
    page: 1,
    size: 10,
    status: 0,
  };
  getList();
};

const onChange =  async(v, id) => {
  if (store.userInfo.id === id) {
    return message.warning('不能操作当前登录的账号。');
  }
  
  spinning.value = true;
  let res;
  if (v) {
    res = await publisherAdminApi.enableUser({ id });
  } else {
    res = await publisherAdminApi.disableUser({ id });
  }
  if (res) {
    const { success, error } = res;
    if (success) {
      if (!v && store.userInfo.id === id) {
        location.href = '/#/operation/login';
      } else {
        message.success('Success')
        getList();
      }
    } else {
      message.error(error);
    }
  }
  spinning.value = false;
};

const onChangePage = (data) => {
  const { current, pageSize } = data;
  params.value.page = current;
  params.value.size = pageSize;
  getList();
};

const getList = () => {
  spinning.value = true;
  let newParams = { ...params.value };
  if (searchType.value === "name") {
    newParams.nameKeyword = inputValue.value;
    newParams.emailKeyword = "";
  } else {
    newParams.emailKeyword = inputValue.value;
    newParams.nameKeyword = "";
  }
  publisherAdminApi.getUserList(newParams).then((res) => {
    const { data, success, error } = res;
    if (success) {
      data.data.map((item) => {
        item.isActive = item.status == 0;
      });
      dataList.value = data.data;
      listTotal.value = data.total;
    } else {
      message.error(error);
    }
  });
  spinning.value = false;
};

onMounted(() => {
  getList();
});
</script>

<style lang="less" scoped>
.users {
  padding: 16px;
  padding-top: unset;
}
.table {
  border-radius: 12px;
  overflow: hidden;
  border-radius: 12px;
  background-color: var(--color-white);
  padding: 15px;
  .editable-cell-btn {
    > button {
      margin-left: 10px;
    }
  }
  :deep(.ant-popover-content) {
    .ant-popover-inner {
      padding: 15px;
    }
  }
  .search-btn {
    padding-bottom: 15px;
    display: flex;
    justify-content: right;
    .reset-btn {
      display: flex;
      > button {
        margin-left: 10px;
      }
    }
  }
}
</style>